<template>
  <v-card class="card" color='primary'>
    <v-card-title class="text-gold pacifico text-shadow-black text-center">{{ $t('Confirmation') }}</v-card-title>
    <v-card-text class="mt-3 mb-2 text-center">{{ $t(message) }}</v-card-text>
    <v-card-actions class="justify-space-around">
      <Button class="no nunito" :color="'white'" :width='135' :height='40' @click="$emit('result', false)">{{ $t('No') }}</Button>
      <Button class="nunito" :color="'#ffcb00'"  :width='135' :height='40' @click="$emit('result', true)">{{ $t('Yes') }}</Button>
    </v-card-actions> 
  </v-card>
</template>
<script>
export default {
  name: 'YesNo',
  components: {
    Button: () => import('@/components/ui/Button')
  },
  props: {
    message: String
  }
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/_classes.scss';

  .card {
    width: 100%;
    height: 100%;
  }

  .v-card__title { font-size: 28px; }

  .v-card__text {
    color: white !important;
    padding: 0 !important;
    font-size: 17px;
  }

  .v-btn { 
    box-shadow: none;
    text-shadow: none;
    text-shadow: 1px 1px 1px black; 
    font-weight: bold;
    letter-spacing: 0px;

    &.no { 
      color: black; 
      text-shadow: 1px 1px 1px gray; 
    }
  }
</style>